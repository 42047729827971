import Rimconfigurator from "../modules/Rimconfigurator/pages/carSelection/CarSelection";
import NotFoundPage from "./components/layout/NotFoundPage";
import SelectRim from '../modules/Rimconfigurator/pages/rims/SelectRim';
import noRimsFound from "./components/layout/noRimsFound";

export default [
   {
      path: "/manualSelect/:manufacturerId?/:modelId?/:typesId?",
      component: Rimconfigurator,
      exact: false
   },

   {
      path: "/",
      component: Rimconfigurator,
      exact: true
   },
   {
      path: "/car/:id/:rimId?",
      component: SelectRim,
      exact: true
   },
   {
      path: "/noRimsFound",
      component: noRimsFound,
      exact: true
   },
   {
      path: "/*",
      component: NotFoundPage
   }
];
