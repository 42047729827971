import React from 'react';
import * as actionCreators from "../../../redux-store/actions";
import {connect} from "react-redux";

class Header extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        const domain = this.props.domain ? this.props.domain : "https://point-s.de";
        return (
            <div className={'header marg-top-header'}>
                <div className={'container d-flex align-items-center text-center'}>
                    <a href={'/'}>
                        <img src="/points.png" className="img-fluid logo-size" alt={""}/>
                    </a>
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => ({
    firmData: state.firmData,
    domain: state.domain
});

const mapDispatchToProps = dispatch => {
    return {
        dispatch,

    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Header);

