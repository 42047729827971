import React from 'react';
import {Link} from 'react-router-dom';

const noRimsFound = ({staticContext = {}}) => {
   staticContext.notFound = true;
   return (
      <div className="error-page text-center">
         <div>
            <Link to={"/"}>
               <div className="image-pos">
                  <img src="/errorpage.png"
                       className="img-fluid img-size errorimg"
                  />
               </div>
            </Link>
         </div>
         <h2>
            <b>Für dieses Auto werden zurzeit keine Felgen angeboten</b>
            <br/>
            Hier geht es zurück zur Autoauswahl:
         </h2>
         <div className="button-pos">
            <Link className="btn btn-primary" to={"/"}>
               Zur Suche
            </Link>
         </div>
      </div>
   );
};

export default noRimsFound;
