import 'babel-polyfill';
import 'react-app-polyfill/stable';
import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter as Router } from "react-router-dom";
import { Provider as ReduxProvider } from "react-redux";
import { persistStore, REHYDRATE } from "redux-persist";
import { PersistGate } from "redux-persist/lib/integration/react";
import "bootstrap";
import Layout from "./components/layout/Layout";
import createStore from "../redux-store/store";

require("./assets/scss/style.scss");
const store = createStore(window.REDUX_DATA);
const persistor = persistStore(store, {}, () => {
  store.dispatch({ type: REHYDRATE });
});

const jsx = (
  <PersistGate loading={null} persistor={persistor}>
    <ReduxProvider store={store}>
      <Router>
        <Layout  />
          </Router>
    </ReduxProvider>
  </PersistGate>
);

const app = document.getElementById("app");
ReactDOM.render(jsx, app)
