import React from 'react';
import {Route, Switch} from 'react-router-dom';
import {CSSTransition, TransitionGroup} from 'react-transition-group';
import routes from '../../routes';
import Breadcrumb from '../shared/breadcrumb/Breadcrumb.js';
import Footer from '../layout/Footer';
import Header from '../layout/Header';

class Layout extends React.Component {
   constructor() {
      super();
      this.state = {
         loc: '',
         heightFooter: '',
      };
   }

   componentDidMount() {
      this.setState({heightFooter: document.getElementById('Footer').clientHeight});
   }

   render() {
      return (
         <div>
            <Route
               render={({location}) => {
                  this.state.loc = location.pathname;
                  const {pathname} = location;
                  return (
                     <div className="bodywrapper">
                        <TransitionGroup>
                           <CSSTransition
                              key={pathname}
                              classNames="page"
                              timeout={{
                                 enter: 1000,
                                 exit: 0
                              }}>
                              <Route location={location}
                                     render={() => (
                                        <div>
                                           <Header loc={pathname}/>
                                           <Breadcrumb path={location.pathname}/>
                                           <div>
                                              <Switch>
                                                 {routes.map(route => (
                                                    <Route key={route.path} {...route} />
                                                 ))}
                                              </Switch>
                                           </div>
                                        </div>
                                     )}
                              />
                           </CSSTransition>
                        </TransitionGroup>
                        <Footer this={this}/>
                     </div>
                  );
               }}
            />
         </div>
      );
   }
}

export default Layout;
